import { stripHtml } from "utils/ValidationUtils";
import * as Yup from "yup";

export const ProjectFormValidationSchema = Yup.object({
  basicDetailsForm: Yup.object({
    shouldDisplaySalutationActive: Yup.boolean(),
    shouldDisplayMiddleName: Yup.boolean(),
    shouldDisplayDateOfBirth: Yup.boolean(),
    formHeader: Yup.string().required("Please enter Form Header"),
    numberOfPlots: Yup.string().required("Please enter number of plots"),
    typeOfApplicant: Yup.array()
      .min(1, "Please add atleast one type of applicant")
      .required("Please add atleast one type of applicant"),
    residentialStatus: Yup.array()
      .min(1, "Please add atleast one Residential status")
      .required("Please add atleast one Residential status"),
    salutation: Yup.array()
      .min(1, "Please add atleast one Salutation")
      .required("Please add atleast one Salutation"),
    gender: Yup.array()
      .min(1, "Please add atleast one gender")
      .required("Please add atleast one gender"),
    termsAndConditions: Yup.string()
      .required("Please Enter Terms And Conditions")
      .min(50, (obj) => {
        if (!stripHtml(obj.value)) return `Please Enter Terms And Conditions`;
      }),
    privacyDescription: Yup.string()
      .required("Please Enter Privacy Description")
      .min(50, (obj) => {
        if (!stripHtml(obj.value)) return `Please Enter Privacy Description`;
      }),
    conditionDescription: Yup.string()
      .required("Please Enter Conditions Description")
      .min(50, (obj) => {
        if (!stripHtml(obj.value)) return `Please Enter Conditions Description`;
      }),
    returnPolicyDescription: Yup.string()
      .required("Please Enter Return Policy Description")
      .min(50, (obj) => {
        if (!stripHtml(obj.value)) return `Please Enter Conditions Description`;
      }),
    reraDetails: Yup.object({
      projectName: Yup.string().required("Please enter project/launch name"),
      companyRegistrationAddress: Yup.string().required(
        "Please enter company address"
      ),
      gstinNumber: Yup.string().required("Please enter GSTIN number"),
      staticLableThree: Yup.string().required(
        "Please enter static Lable Three"
      ),
      //   phase: IReraPhase[],
      phase: Yup.array().of(
        Yup.object({
          phaseName: Yup.string().required("Please enter phase name"),
          // reraNumber: Yup.string().required("Please enter RERA number"),
          reraNumber: Yup.string()
          .matches(/^[A-Za-z0-9\s!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|-]*$/, "Invalid characters in RERA number")
          .required("Please enter RERA number"),
        })
      ),
      registeredProjects: Yup.string().required(
        "Please enter registered projects"
      ),
      websiteName: Yup.string().required("Please enter website name"),
    }),
    contactDetails: Yup.object({
      // phoneNumber: Yup.string()
      //   .matches(
      //     /^((?!(0))[0-9]{10})$/,
      //     "Phone number shouldn't start with 0 and must have 10 digit "
      //   )
      //   .required("This field is mandatory"),
      email: Yup.string().email("Invalid email address"),
    }),
    // contactDetails: Yup.object({
    //   companyAddress: Yup.string().required("Please enter company address"),
    //   phoneNumber: Yup.string().required("Please enter phone number"),
    //   email: Yup.string().required("Please enter email"),
    // }),
  }),
  essentialDetailsForm: Yup.object({
    isAddressLineTwoActive: Yup.boolean(),
    isDesignationActive: Yup.boolean(),
    isApplicantEmployerOrCompanyActive: Yup.boolean(),
    isPassportNumberActive: Yup.boolean(),
    isPassportUploadActive: Yup.boolean(),
    isPanCardUploadActive: Yup.boolean(),
    isPanCardNumberActive: Yup.boolean(),
    isAadharCardUploadActive: Yup.boolean(),
    isAadharCardNumberActive: Yup.boolean(),
    isCompanyOrHUFPanUploadActive: Yup.boolean(),
    isCompanyOrHUFPanNumberActive: Yup.boolean(),

    occupation: Yup.array()
      .min(1, "Please add atleast one occupation")
      .required("Please add atleast one occupation"),
    maritalStatuses: Yup.array()
      .min(1, "Please add atleast one marital status")
      .required("Please add atleast one marital status"),
    sourceOfFunding: Yup.array()
      .min(1, "Please add atleast one source of funding")
      .required("Please add atleast one source of funding"),
    puposeOfPurchase: Yup.array()
      .min(1, "Please add atleast one purpose of purchase")
      .required("Please add atleast one purpose of purchase"),
  }),
  // paymentFormBanner: Yup.object().shape({
  //   mediaWeb: Yup.object().shape({
  //     value: Yup.object().shape({
  //       url: Yup.mixed().required("Uplaod Image with mentioned dimensions!"),
  //     }),
  //   }),
  //   mediaMobile: Yup.object().shape({
  //     value: Yup.object().shape({
  //       url: Yup.mixed().required("Uplaod Image with mentioned dimensions!"),
  //     }),
  //   }),
  //   mediaTab: Yup.object().shape({
  //     value: Yup.object().shape({
  //       url: Yup.mixed().required("Uplaod Image with mentioned dimensions!"),
  //     }),
  //   }),
  // }),
});
