import { SagaIterator } from "@redux-saga/types";
import { HttpResponseTypeEnum } from "enumerations/HttpResponseTypeEnum";
import { call, put } from "redux-saga/effects";
import QrListActions from "../../redux-container/qr-generation/qrRedux";

export function* getQrList(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getQrList, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(QrListActions.getQrSuccess(response.data));
    } else {
      yield put(QrListActions.getQrFailure(response.data));
    }
  } catch (error) {
    yield put(QrListActions.getQrFailure(error));
  }
}

export function* generateQr(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.generateQr, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(QrListActions.generateQrSuccess(response.data));
    } else {
      yield put(QrListActions.generateQrFailure(response.data));
    }
  } catch (error) {
    yield put(QrListActions.generateQrFailure(error));
  }
}

export function* updateQr(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateUri, action.id, action.data);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(QrListActions.updateQrSuccess(response.data));
    } else {
      yield put(QrListActions.updateQrFailure(response.data));
    }
  } catch (error) {
    yield put(QrListActions.updateQrFailure(error));
  }
}

export function* deleteQr(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteQr, action.id);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(QrListActions.deleteQrSuccess(response.id));
    } else {
      yield put(QrListActions.deleteQrFailure(response.data));
    }
  } catch (error) {
    yield put(QrListActions.deleteQrFailure(error));
  }
}