import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import MediaAssets from "assets";
import { SwitchComponent, TextFieldComponent } from "components/ui-elements";
import { FieldArray, getIn, useFormikContext } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleChangeNoSpecialCharacter } from "utils/ValidationUtils";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import { GeneralInfoConst } from "../general-information/GeneralInfoConstant";
import styles from "./ReraDetails.module.css";
import ReraDocumentDetails from "./ReraDocuments";

import ImageUploadDragDrop, {
  DragAndDropStyle,
} from "./ImageUploader/ImageUploader";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import Loader from "components/loader/Loader";

const actionDispatch = (dispatch: any) => {
  return {
    fileUpload: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    addMediaUpload: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
  };
};
const ReraDetails = () => {
  const { fileUpload, addMediaUpload } = actionDispatch(useDispatch());
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
    errors,
    touched,
    isValid,
  } = useFormikContext<IInitinalValueProjectContent>();
  console.log("ReraDetails values", values);

  const [callingUpload, setCallingUpload] = useState(-1);
  const [loadingFile, setLoadingFile] = useState<Boolean>(false);
  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  const assignEmpty: string = "";
  const { isMediaLoading, isSuccess, fileUploadDetails } = useSelector(
    (state: any) => ({
      isMediaLoading: state?.fileUpload?.isLoading,
      isSuccess: state?.fileUpload?.isSuccess,
      fileUploadDetails: state?.fileUpload?.imageDetails,
    })
  );
  const handleuploadImage = async (
    file: any,
    setCount: any,
    setFieldValue?: any
    // path?: any,
    // value?: any
  ) => {
    const fileExtension = file?.name?.slice(
      ((file?.name?.lastIndexOf(".") - 1) >>> 0) + 2
    );
    if (
      fileExtension == "png" ||
      fileExtension == "jpg" ||
      fileExtension == "jpeg" ||
      fileExtension == "svg"
    ) {
      let imageUrl;
      if (file) {
        const data = {
          name: `${Math.floor(Math.random() * 20).toString()}/` + file?.name,
          file,
          urlType: "upload",
          binary: "",
        };
        const reader = new FileReader();
        reader.onload = (event: any) => {
          data.binary = event.target.result;
          fileUpload(data);
        };

        reader.readAsArrayBuffer(file);
        setCallingUpload(setCount);
      } else {
        imageUrl = null;
      }
    } else {
      alert("Only .png, .jpg, .jpeg,.svg is accepted !");
    }
  };
  useEffect(() => {
    if (callingUpload >= 0) {
      setFieldValue(
        `reraDetails.reraCodes[${callingUpload}].qrCode`,
        fileUploadDetails?.url
      );
    }
  }, [isMediaLoading]);

  return (
    <Fragment>
      <Box p={3}>
        {" "}
        <div className={styles["accordion-global-padding"]}>
          <Stack
            alignItems="center"
            justifyContent="flexStart"
            spacing={2}
            mb={1}
          >
            <Typography variant="h6" color="#000">
              Rera Basic Details
            </Typography>
            <Typography fontSize={"0.5rem"} color="#000">
              App Toggle{" "}
            </Typography>

            <SwitchComponent
              name="reraDetails.isReraDetailsActive"
              onChange={handleChange}
              value={values?.reraDetails?.isReraDetailsActive}
              checked={values?.reraDetails?.isReraDetailsActive}
              disabled={isViewMode}
            />
            <Tooltip
              arrow
              title={"Toggle is to show/hide this section on the Customer App"}
              placement="top"
            >
              <Avatar
                className={styles["info-icon"]}
                src={MediaAssets.ic_info}
                variant="rounded"
              />
            </Tooltip>
            <Typography fontSize={"0.5rem"} color="#000">
              Web Toggle{" "}
            </Typography>

            <SwitchComponent
              name="reraDetails.isReraDetailsActiveForWeb"
              onChange={(e: any) => {
                setFieldValue(
                  "reraDetails.isReraDetailsActiveForWeb",
                  e?.target?.checked
                );
              }}
              value={values?.reraDetails?.isReraDetailsActiveForWeb}
              checked={values?.reraDetails?.isReraDetailsActiveForWeb}
              disabled={isViewMode}
            />
            <Tooltip
              arrow
              title={"Toggle is to show/hide this section on the Web"}
              placement="top"
            >
              <Avatar
                className={styles["info-icon"]}
                src={MediaAssets.ic_info}
                variant="rounded"
              />
            </Tooltip>
          </Stack>
        </div>
        <div className={styles["accordion-global-padding"]}>
          <Grid container spacing={2}>
            <Grid item xs={11.3} sm={11.3} md={11.3} lg={11.3} xl={11.3}>
              <TextFieldComponent
                required
                fullWidth
                multiline
                maxRows={10}
                helpericon={MediaAssets.ic_info}
                placeholder="Enter"
                helpericoncontent={
                  "This detail will appear in tool tip in Project detail page"
                }
                name="reraDetails.companyNameAndAddress"
                label={"Company Name & Registered Address"}
                value={values?.reraDetails?.companyNameAndAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                fieldhelpertext={
                  getIn(touched, `reraDetails.companyNameAndAddress`) &&
                  getIn(errors, `reraDetails.companyNameAndAddress`)
                }
                error={Boolean(
                  getIn(errors, `reraDetails.companyNameAndAddress`) &&
                    getIn(touched, `reraDetails.companyNameAndAddress`)
                )}
                // fieldhelpertext={touched && touched?.reraDetails?.companyNameAndAddress && values?.reraDetails?.isReraDetailsActive === true && values?.reraDetails?.companyNameAndAddress === "" && "Required"  }
                // error={touched && touched?.reraDetails?.companyNameAndAddress && values?.reraDetails?.isReraDetailsActive === true && values?.reraDetails?.companyNameAndAddress === "" ? true :false}

                inputProps={{
                  "data-testId": "gps-location-link",
                  maxLength: GeneralInfoConst.reraAddressCharacterLimit,
                }}
                disabled={isViewMode}
              />
            </Grid>

            <Grid item xs={12}>
              <FieldArray
                name="reraDetails.reraNumbers"
                render={(arrayHelper) => (
                  <div>
                    {values?.reraDetails?.reraNumbers?.map(
                      (fieldValue: any, index: any) => (
                        <div>
                          <Stack>
                            <Grid
                              item
                              xs={6}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <TextFieldComponent
                                required
                                disabled={isViewMode}
                                name={`reraDetails.reraNumbers[${index}]`}
                                placeholder="Enter"
                                label={"Rera Number"}
                                value={values?.reraDetails?.reraNumbers[index]}
                                onChange={handleChange}
                                // onChange={handleChangeNoSpecialCharacter(
                                //   setFieldValue,
                                //   `reraDetails.reraCodes[${index}].reraNumber`,
                                //   values?.reraDetails?.reraNumbers[index]
                                // )}
                                onBlur={handleBlur}
                                fieldhelpertext={
                                  getIn(
                                    touched,
                                    `reraDetails.reraNumbers[${index}]`
                                  ) &&
                                  getIn(
                                    errors,
                                    `reraDetails.reraNumbers[${index}]`
                                  )
                                }
                                error={Boolean(
                                  getIn(
                                    errors,
                                    `reraDetails.reraNumbers[${index}]`
                                  ) &&
                                    getIn(
                                      touched,
                                      `reraDetails.reraNumbers[${index}]`
                                    )
                                )}
                              />
                              {values?.reraDetails?.reraNumbers?.length > 0 && (
                                <Typography
                                  sx={{
                                    textDecoration: "underline",
                                    marginRight: "-50px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    // !isViewMode &&
                                    // setFieldValue(
                                    //   `reraDetails.reraCodes[${index}].qrCode`,

                                    //   ""
                                    // );
                                    // !isViewMode &&
                                    //   setFieldValue(
                                    //     `reraDetails.reraCodes[${index}].reraNumber`,

                                    //     ""
                                    //   );
                                    values?.reraDetails?.reraCodes.splice(
                                      index,
                                      1
                                    );

                                    !isViewMode && arrayHelper.remove(index);
                                  }}
                                >
                                  <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={4} md={4} lg={4} xl={4}>
                              {/* <Stack justifyContent={"flex-start"} mb={5}>
                                <Typography data-testid="image-media">
                                  Qr Code
                                </Typography>
                                <IconButton>
                                  <Tooltip
                                    title={"Upload Image"}
                                    placement="top"
                                  >
                                    <AvatarWrapper
                                      src={MediaAssets.ic_info}
                                      variant="rounded"
                                    />
                                  </Tooltip>
                                </IconButton>
                              </Stack> */}

                              <Grid>
                                <ImageUploadDragDrop
                                  onDrop={handleChange}
                                  name={`reraDetails.reraCodes[${index}].qrCode`}
                                  value={
                                    values?.reraDetails?.reraCodes
                                      ? values?.reraDetails?.reraCodes[index]
                                          ?.qrCode
                                      : assignEmpty
                                  }
                                  onBlur={handleBlur}
                                  onChange={(file: any) => {
                                    handleuploadImage(
                                      file,
                                      index,
                                      setFieldValue
                                    );
                                    setFieldValue(
                                      `reraDetails.reraCodes[${index}].reraNumber`,

                                      values?.reraDetails?.reraNumbers[index]
                                    );
                                  }}
                                  isLoading={
                                    callingUpload == index &&
                                    isMediaLoading == true
                                      ? true
                                      : false
                                  }
                                  previewimage={
                                    values?.reraDetails?.reraCodes &&
                                    values?.reraDetails?.reraCodes.length > 0 &&
                                    values?.reraDetails?.reraCodes[index]
                                      ?.qrCode !== ""
                                      ? values?.reraDetails?.reraCodes[index]
                                          ?.qrCode
                                      : assignEmpty
                                  }
                                  cancelimage={() => {
                                    let empty = "";
                                    setFieldValue(
                                      `reraDetails.reraCodes[${index}].qrCode`,
                                      empty
                                    );
                                  }}
                                  isDisabled={
                                    values?.reraDetails?.reraNumbers[index]
                                      .length > 0
                                      ? false
                                      : true
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Stack>
                          {values?.reraDetails?.reraNumbers?.length - 1 ===
                            index && (
                            <Link
                              underline="always"
                              component="button"
                              onClick={() => arrayHelper.push("")}
                              disabled={isViewMode}
                            >
                              + Add more
                            </Link>
                          )}
                        </div>
                      )
                    )}
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </div>
      </Box>
      <Divider />

      <Box p={3}>
        <ReraDocumentDetails />
      </Box>
    </Fragment>
  );
};

export default ReraDetails;