import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import configureStore from "./CreateStore";
import rootSaga from "../sagas";

export const reducers = combineReducers({
  login: require("./login/LoginRedux").reducer,
  forgotPassword: require("./forgot-password/ForgotPasswordRedux").reducer,
  resetPassword: require("./reset-password/ResetPasswordRedux").reducer,
  users: require("./users/UsersRedux").reducer,
  rolesAndModules: require("./users/RolesAndModulesRedux").reducer,
  inviteUsers: require("./users/InviteUserRedux").reducer,
  userLogs: require("./users/UserLogsRedux").reducer,
  changePassword: require("./change-password/ChangePasswordRedux").reducer,
  promisesList: require("./promises/PromisesListRedux").reducer,
  promiseDashboard: require("./promises/PromiseRedux").reducer,
  promisesCreation: require("./promises/PromisesCreationRedux").reducer,
  referrals: require("./referrals/ReferralsRedux").reducer,
  faq: require("./faq/FaqRedux").reducer,
  resourceDashboard: require("./resource-management/ResourceDashboardRedux")
    .reducer,
  pageDashboard: require("./page-management/PageDashboardRedux").reducer,
  page3Promises: require("./page-management/Page3PromiseRedux").reducer,
  categories: require("./resource-management/CategoryRedux").reducer,
  testimonials: require("./marketing-management/testimonials/TestimonialsRedux")
    .reducer,
  blogDashboard: require("./blog-management/BlogRedux").reducer,
  blogCreation: require("./blog-management/BlogCreationRedux").reducer,
  projectList: require("./project-management/ProjectListRedux").reducer,
  projectDrafts:
    require("./project-management/project-drafts/ProjectDraftsRedux").reducer,
  projectCollectionList:
    require("./project-management/project-collection/ProjectCollectionListRedux")
      .reducer,
  projectCollectionCreation:
    require("./project-management/project-collection/ProjectCollectionCreationRedux")
      .reducer,
  marketingDashboard: require("./marketing-management/MarketingDashboardRedux")
    .reducer,
  landingPageDashboard:
    require("./landing-page-management/LandingPageDashboardRedux").reducer,
  landingPageUrlCreation:
    require("./landing-page-management/LandingPageUrlCreationRedux").reducer,
  marketingUpdates:
    require("./marketing-management/updates/UpdateCreationRedux").reducer,
  fileUpload: require("./file-upload-S3/fileUploadRedux").reducer,
  generalFaq: require("./faq/GeneralFaqRedux").reducer,
  allCategoriesForFaq: require("./faq/GetAllCategoriesRedux").reducer,
  insightsCreation: require("./insights-creation/InsightsCreationRedux")
    .reducer,
  update: require("./marketing-management/updates/UpdatesRedux").reducer, // We need to use marketingUpdates reducer which yuvraj wrote
  insights: require("./insights/InsightsRedux").reducer,
  faqList: require("./resource-management/FaqListRedux").reducer, //dont discard this line
  qrGenerationList: require('./qr-generation/qrRedux').reducer,
  pageManagement: require("./page-management/PageManagementRedux").reducer,
  projectContentListHoablPage1:
    require("./page-management/hoabl-page-1/ProjectListInHoablPage1Redux")
      .reducer,
  hoablPage1Data: require("./page-management/hoabl-page-1/HoablPage1Redux")
    .reducer,
  pagePortfolio: require("./page-management/PagePortfolioRedux").reducer,
  projectContents: require("./page-management/ProjectContentsRedux").reducer,
  projectMediaGallery:
    require("./project-media-gallery-s3-upload/ProjectMediaGalleryUpload")
      .reducer,
  projectContentManagement:
    require("./project-management/project-content/ProjectContentRedux").reducer,
  projectLaunchPhases:
    require("./project-management/launch-phases/LaunchPhasesRedux").reducer,
  crmFetchUpdates: require("./project-management/crm-updates/CrmUpdates")
    .reducer,
  //projectCreation
  projectCategories: require("./project-management/ProjectCategoryRedux")
    .reducer,
  projectFaqs: require("./project-management/ProjectFaqsRedux").reducer,
  projectFaqsCategories:
    require("./project-management/ProjectCategoryForFaqCreationRedux").reducer,
  projectAllfaqs: require("./project-management/ProjectAllFaqsRedux").reducer,

  projectAllFaqsList:
    require("./project-management/project-content/ProjectAllFaqsListRedux")
      .reducer,

  notifications: require("./notification-management/NotificationsRedux")
    .reducer,
  notificationDashboard:
    require("./notification-management/NotificationDashboardRedux").reducer,
  chatManagement: require("./chat-management/ChatManagementRedux").reducer,
  chatManagementList: require("./chat-management/ChatManagementListRedux")
    .reducer,
  customerManagement: require("./customer-management/CustomerManagementRedux")
    .reducer,
  customerListAndDetails:
    require("./customer-management/CustomersListAndDetailsRedux").reducer,
  blockedCustomerList: require("./customer-management/BlockedCustomerListRedux")
    .reducer,
  auditLog: require("./audit-log/auditLogRedux").reducer,
  formsList: require("./form-list/FormListRedux").reducer,
  paymentForm: require("./payment-forms/paymentFormRedux").reducer,
  upCommingProjects:
    require("./project-management/upcomming-project/UpcommingProjectRedux")
      .reducer,
  leads: require("./lead-management/LeadRedux").reducer,
  //media and prs
  mediaAndPrs: require("./marketing-management/media-and-prs/MediaAndPrRedux")
    .reducer,
  appUpdate: require("./notification-management/AppUpdatesRedux")
    .reducer,
});

export default () => {
  const finalReducers = persistReducer(
    {
      key: "root",
      storage,
    },
    reducers
  );

  const { store } = configureStore(finalReducers, rootSaga);
  const persistor = persistStore(store);
  return { store, persistor };
};
